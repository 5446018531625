import React from 'react'

import Anchor from '@/components/base/anchor/Anchor'
import { Column, Container, Row } from '@/components/base/gridview'

import { VideosProps } from './types'

const Videos: React.FunctionComponent<VideosProps> = ({
  title,
  link,
  children,
}) => {
  return (
    <div className="videos">
      <div className="head">
        <Container>
          <Row>
            <Column>
              <div className="title">{title}</div>
              <Anchor className="link" {...link} />
            </Column>
          </Row>
        </Container>
      </div>
      {children}
    </div>
  )
}

export default Videos
